<template>
  <div id="steps">
    <el-steps :active="2" finish-status="finish" process-status="process" :align-center="true" space="33.333%">
      <el-step icon="el-icon-user-solid">
        <template slot="title"><span class="title">个人信息</span></template>
      </el-step>
      <el-step icon="el-icon-s-order">
        <template slot="title"><span class="title">填写问卷</span></template>
      </el-step>
      <el-step icon="el-icon-success">
        <template slot="title"><span class="title">报名完成</span></template>
      </el-step>
    </el-steps>
  </div>
</template>

<script>
export default {
  name: "Steps"
}
</script>

<style scoped lang="scss">
#steps {
  width: 100%;
  height: 100px;
  background-color: #fff;
  box-sizing: border-box;
  margin: 10px 0;
  .title {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 22px;
  }
}
</style>
