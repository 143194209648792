<template>
<div id="main" >
  <banner></banner>
  <steps v-show="false"></steps>
  <router-view></router-view>
</div>
</template>

<script>
import Banner from "@/views/form/Banner";
import Steps from "@/views/form/Steps";

export default {
name: "Main",
  components: { Banner, Steps }
}
</script>

<style scoped>
#main {
  margin: 0 100px;
}
</style>
