<template>
  <div class="banner">
    <img :src="$store.state.application.properties.headerBackground" />
  </div>
</template>

<script>
export default {
  name: "Banner",
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;

  img {

    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
